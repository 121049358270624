<template>
  <v-row class="bbr-trainer--details-form">
    <v-col cols="12" sm="6">
      <v-text-field
        v-if="!isLoading"
        v-model="form.first_name"
        label="Trainer First Name"
        :disabled="saving"
        :error-messages="form.$getError('first_name')"
        outlined
        flat
      />

      <v-skeleton-loader v-else type="text" heigh="200" />

      <v-skeleton-loader heigh="200" />
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        v-if="!isLoading"
        v-model="form.last_name"
        label="Trainer Last Name"
        :disabled="saving"
        :error-messages="form.$getError('last_name')"
        outlined
        flat
      />

      <v-skeleton-loader v-else type="text" heigh="200" />

      <v-skeleton-loader heigh="200" />
    </v-col>

    <v-col sm="6" cols="12">
      <v-text-field
        v-if="!isLoading"
        v-model="form.image"
        label="Trainer Image"
        :disabled="saving"
        :error-messages="form.$getError('image')"
        outlined
        flat
      />

      <v-skeleton-loader v-else type="text" heigh="200" />
    </v-col>

    <v-col sm="6" cols="12">
      <v-text-field
        v-if="!isLoading"
        v-model="form.share_image"
        label="Trainer Share Image"
        :disabled="saving"
        :error-messages="form.$getError('share_image')"
        outlined
        flat
      />

      <v-skeleton-loader v-else type="text" heigh="200" />
    </v-col>

    <v-col>
      <v-textarea
        v-if="!isLoading"
        v-model="form.description"
        class="pb-12"
        label="Trainer Description"
        :disabled="saving"
        :error-messages="form.$getError('description')"
        auto-grow
        outlined
        flat
      />

      <v-skeleton-loader v-else type="text@3" heigh="200" />
    </v-col>
  </v-row>
</template>

<script>
import Form from '@/utils/form'
import { mapGetters } from 'vuex'

export default {
  name: 'TrainerDetailsForm',

  props: {
    saving: Boolean,
    data: {
      type: Form,
      required: true,
    },
  },

  data() {
    return {
      form: this.data,
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'trainers/isLoading',
    }),
  },

  watch: {
    data() {
      this.form = this.data
    },
  },
}
</script>
