<template>
  <div class="bbr-trainer--details">
    <v-row>
      <v-col lg="7" md="12">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-skeleton-loader
            v-if="isLoading"
            class="mr-auto"
            type="heading"
            width="400"
          />
          <h2 v-else class="mr-auto">Trainer Details</h2>

          <v-btn
            v-if="!isLoading && hasCurrentTrainerId"
            class="primary--text mr-5"
            :loading="form.$busy"
            @click="$router.go(-1)"
            text
          >
            Back
          </v-btn>

          <v-btn
            v-if="!isLoading"
            class="bg-primary-gradient primary next-button px-12"
            :loading="form.$busy"
            @click="saveTrainerDetails"
            depressed
            text
          >
            <span> Save Trainer </span>
            <v-icon> {{ icons.check }} </v-icon>
          </v-btn>

          <v-skeleton-loader v-if="isLoading" class="mr-5" type="button" />
          <v-skeleton-loader v-if="isLoading" type="button" />
        </div>

        <div class="bbr-trainer--details-form-container">
          <trainer-details-form :data="form" :saving="form.$busy" />
        </div>
      </v-col>
    </v-row>

    <confirm-dialog ref="saveChanges" />
  </div>
</template>

<script>
import TrainerDetailsForm from '../components/TrainerDetailsForm'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import Form from '@/utils/form'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { isEmpty } from 'lodash'
import { mdiCheck } from '@mdi/js'

export default {
  name: 'TrainerDetails',

  components: {
    TrainerDetailsForm,
    ConfirmDialog,
  },

  data() {
    return {
      form: new Form({
        image: null,
        last_name: null,
        first_name: null,
        share_image: null,
        description: null,
      }),
      icons: {
        check: mdiCheck,
      },
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'trainers/isLoading',
      selectedTrainer: 'trainers/selectedTrainer',
    }),

    hasCurrentTrainerId() {
      return !isEmpty(this.$attrs.id)
    },
  },

  created() {
    if (this.hasCurrentTrainerId) {
      this.getTrainer(this.$attrs.id)
    }
  },

  methods: {
    ...mapActions({
      getTrainer: 'trainers/getTrainer',
      saveTrainer: 'trainers/saveTrainer',
    }),

    ...mapMutations({
      insertTrainer: 'trainers/insertTrainer',
    }),

    async saveTrainerDetails() {
      this.form.$busy = true
      this.form.$clearErrors()

      this.saveTrainer(this.form.$data())
        .then((trainer) => {
          this.insertTrainer(trainer)
          this.form.$refresh(trainer)

          this.form.$timeout(() => {
            this.form.$busy = false

            this.$router.push({ name: 'trainers' })
          }, 200)
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },
  },

  watch: {
    selectedTrainer(trainer) {
      this.form = new Form({ ...this.form.$data(), ...trainer })
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (this.form.$isDirty()) {
      const confirm = await this.$refs.saveChanges.open(
        'Save Changes',
        'Do you want to save changes?'
      )

      if (confirm) {
        return this.saveTrainerDetails()
      }
    }

    next()
  },
}
</script>
